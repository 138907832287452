"use strict";

document.addEventListener('DOMContentLoaded', function() {
  var current_index = -1,
      anchor_list = getProductAnchorList(),
      current_page = null,
      current_active = null;
  
  function hidePage() {
    if(current_page) {
      var rect = current_page.getBoundingClientRect();
      
      if(rect.top < 0) {
        window.scrollBy(0, -rect.height);
      }
      
      current_page.parentNode.removeChild(current_page);
      current_page = null;
    }
  }
  
  function pageLoaded(after, page) {
    hidePage();
    
    after.parentNode.insertBefore(page, after.nextSibling);
    current_page = page;
  }
  
  function getNavList(node) {
    while(node && !node.classList.contains('navlist')) {
      node = node.parentNode;
    }
    
    return node;
  }

  function loadOffsetPage(offset) {
    if(current_index !== -1) {
      var target = anchor_list[current_index+offset];

      if(target) {
        loadProductPage(target, true);
      }
    }
  }

  function loadPrevPage() {
    loadOffsetPage(-1);
  }

  function loadNextPage() {
    loadOffsetPage(1);
  }
  
  function getID(node) {
    while(node && !node.getAttribute('id')) {
      node = node.parentNode;
    }
    
    return node ? node.getAttribute('id') : null;
  }
  
  function makeButton(parent, classname, text, callback) {
    var item = document.createElement('li'),
        button = document.createElement('button');
    
    button.classList.add(classname);
    
    if(callback) {
      button.addEventListener('click', callback);
    } else {
      button.disabled = true;
    }
    
    if(text) {
      var span = document.createElement('span');
      span.appendChild(document.createTextNode(text));
      button.appendChild(span);
      button.title = text;
    }
    
    item.appendChild(button);
    parent.appendChild(item);
  }
  
  function makeSpacer(parent) {
    var spacer = document.createElement('li');
    spacer.classList.add('spacer');
    parent.appendChild(spacer);
  }
  
  function hideActive() {
    if(current_active) {
      current_active.classList.remove('active');
      current_active = null;
      current_index = -1;
    }
  }
  
  function closeCurrent() {
    hidePage();
    hideActive();
  }
  
  function getProductAnchorList() {
    var list = [];
    var lists = document.getElementsByClassName('navlist');
  
    for(var i=0; i < lists.length; i++) {
      var anchors=lists[i].getElementsByTagName('a');
      
      for(var j=0; j < anchors.length; j++) {
        list.push(anchors[j]);
      }
    }
    
    return list;
  }

  function textContent(anchor) {
    return anchor.textContent.trim().replace(/\s+/g, ' ');
  }
  
  function home() {
    window.location = /^([^?#]*)/.exec(window.location)[1];
  }
  
  function loadProductPage(anchor, scroll) {
    var id = getID(anchor);
    
    if(id) {
      window.history.replaceState(null, '', '#'+id);
    }
    
    var navlist = getNavList(anchor);
    var old_scroll_offset = null;
    
    if(scroll && current_active) {
      old_scroll_offset = getNavList(current_active).getBoundingClientRect().top;
    }
    
    hideActive();
    
    current_active =  anchor.parentNode;
    current_active.classList.add('active');
    current_index = anchor_list.findIndex((item) => item === anchor);
    
    var request = new XMLHttpRequest();
    request.addEventListener('load', function () {
      var page = document.createElement('div');
      var nav = document.createElement('ul');
      nav.classList.add('buttons');
      
      var prevProduct, nextProduct;
      
      if(current_index !== -1) {
        prevProduct = anchor_list[current_index-1];
        nextProduct = anchor_list[current_index+1];
      }

      if(prevProduct) {
        makeButton(nav, 'prev', textContent(prevProduct), loadPrevPage);
      } else {
        makeButton(nav, 'prev', 'Previous');
      }

      if(nextProduct) {
        makeButton(nav, 'next', textContent(nextProduct), loadNextPage);
      } else {
        makeButton(nav, 'next', 'Next');
      }

      makeSpacer(nav);
      makeButton(nav, 'home', 'Home', home);
      makeButton(nav, 'close', 'Close', closeCurrent);
      
      page.classList.add('page');
      page.appendChild(nav);
      page.appendChild(document.importNode(this.response.getElementsByClassName('product')[0], true));
      pageLoaded(navlist, page);
      
      if(old_scroll_offset) {
        window.scrollBy(0, navlist.getBoundingClientRect().top-old_scroll_offset);
      }
    });
    
    request.open('GET', anchor.href);
    request.responseType = 'document';
    request.send();
  }
  
  function loadPage(event) {
    event.preventDefault();
    loadProductPage(this, false);
  }
  
  var list = getProductAnchorList();
  
  for(var i=0; i < list.length; i++) {
    list[i].addEventListener('click', loadPage, true);
  }
  
  function hashChanged() {
    var foo = /^#(prod\-.*)$/.exec(window.location.hash);
    
    if(!foo) {
      return;
    }
                          
    foo = document.getElementById(foo[1]);
    
    if(!foo) {
      return;
    }
    
    foo = foo.getElementsByTagName('a')[0];
    
    if(foo) {
      loadProductPage(foo);
    }
  }

  document.addEventListener('keydown', (event) => {
    switch(event.code) {
      case 'ArrowLeft':
        loadPrevPage();
      break;

      case 'ArrowRight':
        loadNextPage();
      break;
    }
  })

  var touches = [];

  function swipeDirection(touch) {
    var data = touches[touch.identifier];
    if(!data) {
      return "";
    }

    if(!current_page || data.page !== current_page) {
      // there's nothing to swipe.
      return "";
    }

    if(performance.now()-data.startTime > 1000) {
      // a swipe taking more than 1 second isn't valid.
      return "";
    }
    
    var dX = touch.screenX-data.startX, dY = touch.screenY-data.startY;
    var angle = Math.atan2(dY, dX);
    var distance = Math.sqrt(dX*dX+dY+dY);

    if(distance < 80) {
      // a swipe shorter than 80 pixels isn't valid.
      return "";
    }

    var direction = "";
    var angleThreshold = Math.PI*.1666 // approx 30 degrees.

    if(angle < -Math.PI+angleThreshold || angle > Math.PI-angleThreshold) {
      direction = "left";
    }

    if(angle > -angleThreshold && angle < angleThreshold) {
      direction = "right";
    }

    // if there's already a swipe happening, and it isn't this one, it isn't valid.
    touches.forEach((data, i) => {
      if(data.dir != "" && i != touch.identifier) {
        direction = "";
      }
    })

    return direction;
  }

  function updateTouch(touch) {
    var data = touches[touch.identifier];

    if(!data) {
      return null;
    }

    var dir = swipeDirection(touch);

    if(data.dir != "" && data.dir != dir) {
      data.page.style.transition = 'translate 1s';
      data.page.style.translate = 'none';

      delete touches[touch.identifier];
      return null;
    }

    data.dir = dir;
    data.page.style.transition = 'translate 150ms';
    data.page.style.translate = `${touch.screenX-data.startX}px`;

    return data;
  }

  document.addEventListener('touchstart', (e) => {
    if(current_page && e.target.closest('.page')) {
      for(let i=0; i < e.changedTouches.length; i++) {
        var touch = e.changedTouches[i];
        touches[touch.identifier] = {
          startTime: performance.now(),
          startX: touch.screenX,
          startY: touch.screenY,
          dir: "",
          page: current_page,
        };
      }
    }
  })

  document.addEventListener('touchmove', (e) => {
    for(let i=0; i < e.changedTouches.length; i++) {
      updateTouch(e.changedTouches[i])
    }
  })

  document.addEventListener('touchend', (e) => {
    for(let i=0; i < e.changedTouches.length; i++) {
      var touch = e.changedTouches[i];
      var data = updateTouch(touch);

      if(data) {
        switch(data.dir) {
          case "right":
            touches = [];
            data.page.style.transition = 'translate 1s, opacity 1s';
            data.page.style.translate = '100%';
            data.page.style.opacity = '0';
            loadPrevPage();
          break;

          case "left":
            touches = [];
            data.page.style.transition = 'translate 1s, opacity 1s';
            data.page.style.translate = '-100%';
            data.page.style.opacity = '0';
            loadNextPage();
          break;
        }

        data.dir = "cancel";
        updateTouch(touch);
      }
    }
  })

  document.addEventListener('touchcancel', (e) => {
    for(let i=0; i <e.changedTouches.length; i++) {
      var touch = e.changedTouches[i];
      var data = touches[touch.identifier];
      if(data) {
        data.dir = "cancel";
        updateTouch(touch);
      }
    };
  })
  
  hashChanged();
}, false);
